const API_SERVER_ENDPOINT = `${process.env.REACT_APP_API_SERVER_BASE_URL}/api`;

const STREAM_SERVICE_ENDPOINT = `${process.env.REACT_APP_STREAM_SERVICE_BASE_URL}`;

const TRANSCRIPTION_SERVER_ENDPOINT = `${process.env.REACT_APP_TRANSCRIPTION_SERVER_BASE_URL}/transcription`;

export const AUTHENTICATE_URL = `${API_SERVER_ENDPOINT}/authenticate`;

export const GUEST_AUTHENTICATE_URL = `${API_SERVER_ENDPOINT}/authenticate/guest-user`;

export const GET_ACCESS_TOKEN_URL = `${API_SERVER_ENDPOINT}/refresh-token`;

export const getMeetingHistoryUrl = (accountId, days, upcomingdays) =>
  `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/getMeetingHistory?page=0&size=${days}&upcomingdays=${upcomingdays}`;

export const getUpcomingMeetingsUrl = (accountId, upcomingDays) =>
  `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings?page=0&size=150&pastdays=0&upcomingdays=${upcomingDays}`;

export const getPastMeetingsUrl = (accountId, pastDays) =>
  `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings?page=0&size=150&pastdays=${pastDays}&upcomingdays=-1`;

export const searchMeetingsUrl = (accountId, query) =>
  `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/searches?page=0&size=20&query=${query}`;

export const getMeetingShareLinkUrl = (accountId, meetingId) =>
  `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/${meetingId}/transcription/share-transcript-link`;

export const createMeetingUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings`;

export const createTeamUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/group`;

export const editTeamUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/group/`;

export const getAllGroupUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/participantgroup`;

export const postCreateGroupUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/participantgroup`;

export const getAllUsersAndContactsUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/users/getAllUsersAndContacts`;

export const getGroupDetailsUrl = (accountId, groupId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/participantgroup/${groupId}`;

export const updateGroupDetailsUrl = (accountId, groupId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/participantgroup/${groupId}`;

export const deleteGroupUrl = (accountId, groupId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/participantgroup/${groupId}`;

export const updateMeetingUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/`;

export const updateRecurringMeetingUrl = (accountId, meetingId, recurringMeetingId) =>
  `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/${meetingId}/recurringMeeting/${recurringMeetingId}`;

export const deleteRecurringMeetingUrl = (accountId, meetingId, recurringMeetingId) =>
  `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/deleteMeeting/${meetingId}/${recurringMeetingId}`;

export const searchUsersUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/users/search/`;

export const searchContactsUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/users/searchParticipants/`;

export const searchOnlyContactsUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/users/searchContacts/`;

export const getGuestUserNameUrl = (accountId, email) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/users/searchContacts/${email}`;

export const searchTeamsUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/group/search/`;

export const searchGroupsUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/participantgroup/search/`;

export const searchChildUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/group/search/`;

export const getUserByUidUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/users/uuid/`;

export const getMeetingByIdUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/`;

export const getRecurringMeetingByIdUrl = (accountId, meetingId, recurringMeetingId) =>
  `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/${meetingId}/${recurringMeetingId}`;

export const getTeamByIdUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/group/`;

export const postChatUrl = (accountId, meetingId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/${meetingId}/chat`;

export const addFilesUrl = (accountId, meetingId, fileSource) =>
  `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/${meetingId}/${fileSource}/addFiles`;

export const getProfilePictureUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/getProfilePicture`;

export const saveProfilePictureUrl = (accountId, emailID) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/${emailID}/saveProfile`;

export const getChatUrl = (accountId, meetingId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/${meetingId}/chat`;

export const setChatFlagUrl = (accountId, meetingId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/${meetingId}/chatflag`;

export const inviteUserURL = (accountId, meetingId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/${meetingId}/invite-user`;

export const logEventUrl = (accountId, meetingId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/${meetingId}/log`;

export const getDocumentListUrl = (accountId, meetingId) =>
  `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/${meetingId}/getDocumentList`;

export const addDocumentUrl = (accountId, meetingId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/${meetingId}/addDocument`;

export const resendEmailUrl = (accountId, meetingId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/${meetingId}/resend-invite`;

export const deleteDocumentByIdUrl = (accountId, Id) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/${Id}/deleteDocument`;

export const getMergeTranscriptUrl = (accountId, meetingId) =>
  `${TRANSCRIPTION_SERVER_ENDPOINT}/accounts/${accountId}/meetings/${meetingId}/merge-transcription`;

export const getViewTranscriptUrl = (accountId, meetingId, type = 'gcp') =>
  process.env.REACT_APP_ENVIRONMENT_NAME === 'development'
    ? `${TRANSCRIPTION_SERVER_ENDPOINT}/accounts/${accountId}/meetings/${meetingId}/view-transcription/${type}`
    : `${TRANSCRIPTION_SERVER_ENDPOINT}/accounts/${accountId}/meetings/${meetingId}/view-transcription`;

export const getFullTranscriptAudioUrl = (accountId, meetingId) =>
  `${TRANSCRIPTION_SERVER_ENDPOINT}/accounts/${accountId}/meetings/${meetingId}/get-meeting-audio`;

export const getSummarizationUrl = (accountId, meetingId, queryParams) =>
  queryParams
    ? `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/${meetingId}/transcription/summarization-request/?reportType=${queryParams}`
    : `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/${meetingId}/transcription/summarization-request`;

export const getTranscriptPromptUrl = (accountId, meetingId) =>
  `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/${meetingId}/transcription/transcript-prompt`;

export const getPromptHistoryUrl = (accountId, meetingId, userId) =>
  `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/${meetingId}/transcription/meeting-transcript-prompt/${userId}`;

export const getActionItemsUrl = (accountId, meetingId) =>
  `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/${meetingId}/transcription/action-items-update`;

export const postTranslateUrl = (accountId, meetingId) =>
  `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/${meetingId}/transcription/convert-text`;

export const getUserActionItemsUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/users/action-items-update`;

export const getPinnedActionItemsUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/users/user-action-items/pinned`;

export const postUserActionItemsUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/users/user-action-items`;

export const cancelMeetingUrl = (accountId, meetingId) =>
  `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/cancelMeeting/${meetingId}`;

export const externalUserAuthenticationUrl = (token, accountId) =>
  `${API_SERVER_ENDPOINT}/authenticate/anonymous-user?token=${token}&account=${accountId}`;

export const sendTranscriptToExternalUserUrl = (accountId, meetingId) =>
  `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/${meetingId}/transcription/share-transcript`;

export const sendInviteToRegisterUrl = (accountId, emailId, isMemoriaUser) =>
  `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/send-invite-to-register/${emailId}/${isMemoriaUser}`;

export const createUserUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/users`;

export const getAllUsersUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/users`;

export const createContactUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/users/createContact`;

export const getAllContactsUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/users/getAllContacts`;

export const updateContactUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/users/updateContact`;

export const deleteContactUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/users/deleteContact`;

export const deleteTeamUrl = (accountId, teamID) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/group/deleteTeam/${teamID}`;

export const getAllTeamsUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/group`;

export const getAllAccessibleTeamsUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/group/accessible`;

export const updateUserActiveStatusUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/users/active`;

export const resetPasswordUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/users/reset-password`;

export const forgotPasswordUrl = () => `${API_SERVER_ENDPOINT}/authenticate/forgot-password`;

export const getUserByEmailUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/users/identity`;

export const editProfileUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/users`;

export const editUserEmailUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/users/email`;

export const createAccountUrl = () => `${API_SERVER_ENDPOINT}/accounts/registration`;

export const isUserRegisteredUrl = () => `${API_SERVER_ENDPOINT}/accounts/isuserregistered`;

export const verifyCaptchaUrl = () => `${API_SERVER_ENDPOINT}/accounts/verify-captcha`;

export const getUserAccountUrl = (accountId, userUid) => `${API_SERVER_ENDPOINT}/authenticate/accounts/${accountId}/users/${userUid}`;

export const leaveAccountUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/users/leave-account`;

export const getAllUserAccountsUrl = (accountId, userUid) =>
  `${API_SERVER_ENDPOINT}/accounts/${accountId}/users/${userUid}/user-accounts-list`;

export const signedUrlApiEndpoint = (accountId) =>
  //`http://localhost:8087/account/967ac557-e00a-4d88-b8ee-914b96473a0c/signed-url`
  `${STREAM_SERVICE_ENDPOINT}/accounts/${accountId}/signed-url`;

export const updateAccountName = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/users/update-account-name`;

export const createConversationFlagUrl = (accountId, meetingId) =>
  `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/${meetingId}/addcf`;

export const getallConversationFlagUrl = (accountId, meetingId) =>
  `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/${meetingId}/getcf`;

export const updateConversationFlagUrl = (accountId, meetingId, flagId) =>
  `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/${meetingId}/updatecf/${flagId}`;

export const deleteConversationFlagUrl = (accountId, meetingId, flagId) =>
  `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/${meetingId}/delcf/${flagId}`;

export const getMeetingByDateUrl = (accountId) =>
  `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/getMeetingHistory?page=0&size=30&upcomingdays=0&quickcall=true`;

export const checkUsersStatusUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/users/checkUsersStatus`;

export const quickCallStatusUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/quickCallStatus`;

export const updateDeviceTokenUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/users/updateDeviceToken`;

export const callActionUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/callAction`;

export const reviewFeedbackUrl = (accountId, meetingId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/${meetingId}/feedback`;

export const saveMeetingNotesUrl = (accountId, meetingId) =>
  `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/${meetingId}/meetingNotes`;

export const getNotesForMeetingUrl = (accountId, meetingId, userId) =>
  `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/${meetingId}/meetingNotes/${userId}`;

export const getMeetingByDateRangeUrl = (accountId, startDate, endDate) =>
  `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/getMeetingByDate?page=0&size=500&meetingDate=${startDate}&meetingEndDate=${endDate}`;

// Payments API
export const getActivePlansUrl = (accountId) => `${API_SERVER_ENDPOINT}/plans/account/${accountId}/`;

export const getRemainingFeatureLimitUrl = (accountId) => `${API_SERVER_ENDPOINT}/plans/account/${accountId}/remainingFeatureLimit`;

export const disconnectCallUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/disconnetCall`;

export const getActivePlansExceptPreviewUrl = (queryParams) =>
  queryParams.existingPlan
    ? `${API_SERVER_ENDPOINT}/plans?isPaid=${queryParams.isPaid}&existingPlan=${queryParams.existingPlan}`
    : queryParams.isPaid
    ? `${API_SERVER_ENDPOINT}/plans?isPaid=${queryParams.isPaid}`
    : `${API_SERVER_ENDPOINT}/plans`;

export const getCountriesUrl = () => `${API_SERVER_ENDPOINT}/country`;

// Stripe API
export const stripeSubscriptionUrl = (accountId) => `${API_SERVER_ENDPOINT}/payment/subscription/${accountId}`;

// Get Active Stripe subscription details from Account Id.
export const retriveActiveSubscriptionUrl = (accountId) => `${API_SERVER_ENDPOINT}/payment/subscription/${accountId}`;

// If customer already created in stripe and mapped with account than return stripe customer id
export const createMemoriaStripeAccountUrl = (accountId) => `${API_SERVER_ENDPOINT}/payment/customer/${accountId}`;

// If customer already created in stripe and checkout stripe subscription API
export const checkoutSubscriptionStripeAccountUrl = (accountId) => `${API_SERVER_ENDPOINT}/payment/checkoutSession/${accountId}`;

// Get Active user license details from Account ID.
export const getActiveUserLicenseUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/users/license`;

// Update Subscription Plan.
export const upgradeSubscriptionPlanUrl = (accountId) => `${API_SERVER_ENDPOINT}/payment/subscription/upgrade/${accountId}`;

export const updateProductQuantityUrl = (accountId) => `${API_SERVER_ENDPOINT}/payment/subscription/updateProductQuantity/${accountId}`;
// Calendly
export const updateCalendlyTokenUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/users/updateCalendlyDetails`;

// Webinar
export const getWebinarTokenUrl = () => `${API_SERVER_ENDPOINT}/authenticate/guest-user/webinar`;

export const registerWebinarUrl = (accountId, meetingId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/meetings/${meetingId}/webinar`;

export const bulkContactUploadUrl = (accountId) => `${API_SERVER_ENDPOINT}/accounts/${accountId}/users/uploadContact`;

export const globalNlpPromptUrl = () => `${API_SERVER_ENDPOINT}/v1/nlp/global-prompts`;
